<script setup>
    const props = defineProps(['multiple', 'notext']);
    const emit = defineEmits(['uploaded']);

    const handleDrop = (e) => {
        e.target.form = e.target.closest('form');
        e.target.form.files.files = e.dataTransfer.files;
        upload(e);
    }

    const isLoading = ref(false);
    const upload = async (e) => {
        const { form } = e.target;
		const body = new FormData(form);

        isLoading.value = true;
		const { data } = await $fetch('/api/images/upload', { method: "POST", body });

        
        emit('uploaded', data);
        form.reset();
        
        isLoading.value = false;
	}
</script>

<template>
    <form style="display: contents;" @submit.prevent="upload" @drop.prevent="handleDrop" @dragenter.prevent @dragover.prevent @dragleave.prevent>
        <label class="inline-flex items-center flex-wrap cursor-pointer" v-bind="$attrs">
            <slot>
                <svg-icon v-if="isLoading" class="animate-spin" name="loader" width="24" height="24" />
                <svg-icon v-else name="camera-plus-outline" width="24" height="24" />
            </slot>
            <template v-if="!notext">
                Добавить фото
                <span class="text-sm text-black/m w-full">
                    Можно перетащить его в рамку
                </span>
            </template>
            <input class="clip-hidden" type="file" @change="upload" name="files" accept="image/*" :multiple="multiple">
        </label>
    </form>
</template>